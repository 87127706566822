import React, { useState } from "react";
import bzLogo from "../../images//bizIcon1.svg";
// import ham from "../../images/hamburger_menu.svg";
import close from "../../images/nav-close.svg";
import mobileLogo from "../../images/mob-icon-bzs.svg";
import ham from "../../images/fi_menu.svg";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const goToFeatures = () => {
    setOpen(false);
    if (window.location.pathname === "/") {
      document
        .getElementById("tablet-component")
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      navigate("/");
    }
  };

  const goToPricing = () => {
    setOpen(false);
    if (window.location.pathname === "/") {
      document.getElementById("pricing")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else {
      navigate("/");
    }
  };

  const goToQuestions = () => {
    setOpen(false);
    if (window.location.pathname === "/") {
      document.getElementById("faqs")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else {
      navigate("/");
    }
  };

  window.onscroll = function () {
    scrollFunction();
  };
  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById("on-scroll-nav").style.top = "0";
    } else {
      document.getElementById("on-scroll-nav").style.top = "-50px";
    }
  }

  return (
    <div>
      {open && (
        <div className="nv-mobile-nav">
          <p className="nav-routes-mobile" onClick={goToFeatures}>
            Features
          </p>
          <p className="nav-routes-mobile" onClick={goToPricing}>
            Pricing
          </p>
          <p className="nav-routes-mobile" onClick={goToQuestions}>
            FAQs
          </p>
          <p className="nav-routes-mobile">
            <a
              href="mailto:products@antstack.io"
              target="_blank"
              rel="noreferrer"
              className="bz-mobileNav-contactus"
            >
              Contact
            </a>
          </p>
        </div>
      )}

      <div className="moible-screen-nav">
        <div className="ld-bizos-logo child1">
          {window.location.pathname === "/" ? (
            <>
              <img src={bzLogo} className="ld-circle-logo" alt="bizOZ-logo" />
              <h1
                className={
                  window.location.pathname === "/"
                    ? "bz-heading"
                    : "bz-heading-routes"
                }
              >
                BizOS
              </h1>
            </>
          ) : (
            <>
              <img src={mobileLogo} alt="logo" className="mobileLogoOut" />
              <h1
                className={
                  window.location.pathname === "/"
                    ? "bz-heading"
                    : "bz-heading-routes"
                }
              >
                BizOS
              </h1>
            </>
          )}
        </div>
        {open ? (
          <div
            className="nv-ham-menu1"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <div className="scroll-nav-logo-div-open">
              <img src={mobileLogo} alt="logo" className="mobileLogo" />
              <p className="nv-mob-bizos">BizOS</p>
            </div>
            <img src={close} alt="close-button" className="nav-close-btn" />
          </div>
        ) : (
          <div
            className="nv-ham-menu"
            id="on-scroll-nav"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <div className="scroll-nav-logo-div">
              <img src={mobileLogo} alt="logo" className="mobileLogo" />
              <p className="nv-mob-bizos">BizOS</p>
            </div>
            <img src={ham} alt="" className="ham-menu" />
          </div>
        )}
      </div>

      <div
        className={
          window.location.pathname === "/" ? "nv-wrapper" : "wrapper-routes"
        }
      >
        <div className="ld-bizos-logo child1">
          {window.location.pathname === "/" ? (
            <>
              <img src={bzLogo} className="ld-circle-logo" alt="bizOZ-logo" />
              <h1
                className={
                  window.location.pathname === "/"
                    ? "bz-heading"
                    : "bz-heading-routes"
                }
              >
                BizOS
              </h1>
            </>
          ) : (
            <a href="/" className="bz-routes-logo-img">
              <img src={mobileLogo} alt="logo" className="mobileLogo" />
              <h1
                className={
                  window.location.pathname === "/"
                    ? "bz-heading"
                    : "bz-heading-routes"
                }
              >
                BizOS
              </h1>
            </a>
          )}
        </div>

        <div className="ld-qn-btn child2">
          <p
            className={
              window.location.pathname === "/"
                ? "nav-routes"
                : "nav-routes-black"
            }
            onClick={goToFeatures}
          >
            Features
          </p>
          <p
            className={
              window.location.pathname === "/"
                ? "nav-routes"
                : "nav-routes-black"
            }
            onClick={goToPricing}
          >
            Pricing
          </p>
          <p
            className={
              window.location.pathname === "/"
                ? "nav-routes"
                : "nav-routes-black"
            }
            onClick={goToQuestions}
          >
            FAQs
          </p>

          <p
            className={
              window.location.pathname === "/"
                ? "nav-routes"
                : "nav-routes-black"
            }
          >
            <a href="mailto:products@antstack.io" className="bz-nav-contactus">
              Contact
            </a>
          </p>
        </div>
        <div className="nv-button-wrap child3">
          <p
            className={
              window.location.pathname === "/" ? "hide-faq" : "show-faq"
            }
            onClick={goToQuestions}
          >
            FAQs
          </p>
          <a href="https://app.bizos.io/">
            <button
              className={
                window.location.pathname === "/"
                  ? "bz-sign-button"
                  : "signIn-routes"
              }
            >
              Sign in
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
