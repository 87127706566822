import React from "react";
import "./Pricing.css";
import priceCircle from "../../images/price-circle.svg";
import tick from "../../images/tickmark.svg";
import $ from "../../images/$img.svg";

const Pricing = () => {
  return (
    <>
      <h2 className="pricing-head">Pricing</h2>
      <div className="price-wrapper" id="pricing">
        <div className="price-child1">
          <p className="price-free">FREE</p>
          <h1 className="price-tag">$0</h1>
          <p className="pc-per-user">Per user, per month</p>
        </div>
        <div>
          <hr width="1" size="150" color="#86FFB9" className="vertical" />
        </div>
        <div className="price-child2">
          <div className="feature-points">
            <img src={tick} alt="tickmark" className="tick" />
            <p className="pc-all-features">All Features</p>
          </div>
          <div className="feature-points">
            <img src={tick} alt="tickmark" className="tick" />
            <p className="pc-50-users">Upto 50 Users</p>
          </div>
          <p className="pc-free-message">
            BizOS is Free* for the current{" "}
            <span className="beta-price">(beta) version.</span> Still have
            questions? Contact us.
          </p>
        </div>
        <div className="price-child3">
          <a href="https://app.bizos.io/">
            <button className="pc-get-started">Get Started</button>
          </a>
          <p className="conditiions-message">
            *Subject to change after our Public Release.
          </p>
        </div>
      </div>
      <img src={$} alt="$" className="dollar" />
      <img src={priceCircle} alt="circle$" className="p-circle" />
      <img src={$} alt="$" className="dollar1" />
    </>
  );
};

export default Pricing;
