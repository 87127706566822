export const FaqData = [
  {
    question: "Is the platform open to everyone inside the organisation?",
    answer:
      "Yes, it is. But activity permissions may vary between Admin and User. Also, the Admin must approve everyone who signs up to access it.",
    id: 1,
  },
  {
    question: "What is the Subscription Model?",
    answer:
      "Free for Beta Version. From the next release, the charges will be $1/user/month.",
    id: 2,
  },
  {
    question: "What is BizOS?",
    answer:
      "BizOS is an effective platform/tool that helps you keep track of all the revenue and expenses, along with customer and project information. If you have a growing business, BizOS is the right and essential choice to track your revenue/income and expenses efficiently.",
    id: 3,
  },
  {
    question: "What else can you do with BizOS?",
    answer:
      "Excluding our various useful dashboards, we also have: Graphs and Charts - This feature provides visibility into the organisation’s monthly progress. The generated charts and graphs loaded with detailed information and data help everyone who has access to make valid suggestions. You can take action to grow your business based on the collected inputs. Customisation Feature - You (organisations) can customise the settings, including the logo and other icon features, to cater to your needs and requirements. Role-Management System - You can assign different roles to different people in the organisation; this helps you to restrict sensitive information based on your choice and will. ",
    id: 4,
  },
  {
    question: "Can I request new features?",
    answer: "Yes, you can. We will address your request with priority. ",
    id: 5,
  },
  {
    question: "How to contact us? ",
    answer: "Write to us at: products@antstack.io",
    id: 6,
  },
  {
    question:
      "Is this a Fintech Product? If yes, do you handle real-time data (Revenue, Profit & Loss, Expenditures)?",
    answer:
      "Yes, BizOS is a Fintech product, and we are handling real-time data, which is revenue, expenses and customers’ details. ",
    id: 7,
  },
  {
    question: "Does BizOS come under the RBI guidelines?",
    answer:
      "No, because we are not doing monetary transactions on the BizOS platform.",
    id: 8,
  },
];
