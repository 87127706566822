import React from "react";
import "./Content.css";

import dashboard from "../../images/dashboard-bizos.png";

const Content = () => {
  return (
    <div className="content-parent">
      <div className="child1">
        <h1 className="ct-biz-head-mobile">Monitor how your money moves.</h1>

        <p className="ct-biz-para">
          BizOS lets you track the revenue and expenses of your organization in
          one simplified view to help you be more goal-oriented. BizOS lets you
          track the revenue and expenses of your organization in one simplified
          view to help you be more goal-oriented. 
        </p>
        <div className="ct-inp-parent">
          {/* <input className="ct-input-email" placeholder="E-mail" /> */}
          <a href="mailto:products@anstack.io">
            <button className="ct-demo-btn">Request Demo</button>
          </a>
        </div>
      </div>
      <div className="child2">
        <div className="bg-img">
          <video
            controls
            autoPlay
            muted
            className="video-biz"
            poster={dashboard}
            width="630"
          >
            <source
              src="https://drive.google.com/uc?export=download&id=1O7xqRmhaLc1EOfwFrAaRhMqkUVThQQxV"
              type="video/mp4"
            />
            <source
              src="https://drive.google.com/uc?export=download&id=1O7xqRmhaLc1EOfwFrAaRhMqkUVThQQxV"
              type="video/webm"
            />
          </video>
        </div>
      </div>
    </div>
  );
};

export default Content;
