import React from "react";
import "./Body.css";
import iPadPro from "../../images/iPadPro.svg";
// import csSystem from "../../images/cs-system.svg";
import bizLap from "../../images/lapBarG.svg";
import cirWoman from "../../images/updated-gw.svg";
import crm from "../../images/updated-bw.svg";

const Body = () => {
  return (
    <div className="bd-parent">
      <h1 className="bd-head">How we help you Balance your Time and Money.</h1>

      <div className="bd-child-1" id="tablet-component">
        <img src={bizLap} alt="laptop" className="bd-lap-img" />
        <div className="bd-text-wrap">
          <h2 className="bd-heading-feature">Expense & Revenue Dashboards</h2>
          <p className="bd-description">
            Simpler and easily accessible dashboards designed to minimise your
            efforts. Handle your revenue and understand your expenses with zero
            break-offs.
          </p>
        </div>
      </div>

      <div className="bd-child-2">
        <div className="bd-text-wrap">
          <h2 className="bd-heading-feature">Effective Cost-Tracking</h2>
          <p className="bd-description">
            Be it splurging or saving, earning or investing, your every
            transaction needs better tracking. Manage and monitor your budgets
            by timing them with your current numbers.
          </p>
        </div>
        {/* <img src={csSystem} alt="computer" className="bd-cs-img" /> */}
        <img src={cirWoman} alt="woman-img" className="bd-cirWoman" />
      </div>

      {/* <div className="semi-cir1"></div> */}
      <div className="bd-child-3">
        <div className="semi-cir"></div>
        <img src={iPadPro} alt="iPad-img" className="bd-iPadPro" />

        <div className="bd-text-wrap">
          <h2 className="bd-heading-feature">Optimized Reports</h2>
          <p className="bd-description">
            Generate reports which help you identify challenges & bookmark your
            areas to expand your business. Optimise your weekly team meetings
            with collected data in hand.
          </p>
        </div>
      </div>

      <div className="bd-child-4">
        <div className="bd-text-wrap">
          <h2 className="bd-heading-feature">
            Hassle Free Customer & Project Management
          </h2>
          <p className="bd-description">
            You can keep a tab on all your Projects and Customers anytime you
            need. Sharpen your management skills and grow by streamlining your
            updates to them efficiently.
          </p>
        </div>
        <img src={crm} alt="crm" className="crm" />
      </div>
    </div>
  );
};

export default Body;
