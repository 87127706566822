import React from "react";
import "./CancellationAndRefund.css";
import refund from "../../images/c-r.svg";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

function CancellationAndRefund() {
  return (
    <div className="master-refund">
      <div className="logo-head">
        <Navbar />
        <div className="intro-part1-cr">
          <img src={refund} alt="terms" className="refund-img" />
          <h2 className="c-r-text">Cancellation and Refund Policy</h2>
        </div>
      </div>
      <div className="body-cr">
        <div className="intro-cAndr">
          <p className="paragraph-highlight">Account Cancellation</p>
          <p className="bz-cr-desc">
            We follow a reliable refund policy to let our customers feel
            privileged about their association with us. Please read the
            guidelines governing the refund policy.
          </p>
        </div>
        <div className="content-wrapper-refund">
          <div>
            <p className="paragraph">
              If you wish to cancel your account, notify us at least 15 days
              before end of your term (via an email to admin@antstack.io).
            </p>
            <p className="paragraph">
              Your subscription gets renewed automatically on 1st of every month
              / quarter / year.
            </p>
            <p className="paragraph">
              You can upgrade or downgrade level of our services at any time of
              your usage, however BizOs does not refund any fees in that case
            </p>
            <p className="paragraph">
              If you stop using our services in between the term, we will not
              refund you the fees paid by you for the remaining term
            </p>
            <p className="paragraph">
              We reserve the right to modify or terminate the BizOs service(s)
              for any reason, without notice at any time.
            </p>
            <p className="paragraph">
              Fraud: Without limiting any other remedies, BizOs may suspend or
              terminate your account if we suspect that you (by conviction,
              settlement, insurance or investigation, or otherwise, forcefully
              removed our branding) have engaged in fraudulent activity in
              connection with the services offered by us.
            </p>
            <p className="paragraph">
              Note: No refunds are offered, even if a plan is canceled
              mid-month.
            </p>
          </div>
          <div>
            <h3 className="head-cr">15 Day No Obligation Cancellation</h3>
            <p className="paragraph">
              BizOs offers SaaS service to companies under a 15-day no
              obligation cancellation policy. The no obligation cancellation
              begins immediately upon account activation. The 15-day no
              obligation cancellation period only applies to new customers and
              does not apply to existing customers that have a subscription
              plan/free subscription plan.
            </p>
            <p className="paragraph">
              At any time during the 15-day, potential subscriber (customer) can
              cancel their account and will be refunded in full with a minor
              service charge & development cost.
            </p>
            <p className="paragraph">
              In order to continue using SaaS after the 15-day no obligation
              cancellation period, you will be automatically be put to continue
              to your selected payment plan which you have selected initially at
              time of buying from range of selections of monthly or receiving a
              discount by paying for half yearly or full year of service.
            </p>
          </div>
          <div>
            <h3 className="head-cr">
              Monthly Billing, Cancellation, and Refund Policies
            </h3>
            <p className="paragraph">
              BizOs charges and collects in advance for use of the SaaS service.
              All services rendered are non-refundable. Once a customer selects
              a subscription plan and provides billing information, BizOs will
              calculate a pro-rated amount with the first payment due on
              signing.
            </p>
            <p className="paragraph">
              All subscriptions monthly, quarterly and yearly renew
              automatically on their due renewal date according to date of
              purchase until officially cancelled in writing. Customers may
              cancel anytime by emailing a notice to: admin@antstack.io
            </p>
            <p className="paragraph">
              Customers will receive an email from BizOs confirming that their
              subscription to the SaaS service has been cancelled.
            </p>
            <p className="paragraph">
              Important: No refunds or credits for partial months of service
              will be refunded to a customer upon cancellation.
            </p>
            <p className="paragraph">
              Customers have the ability to upgrade or downgrade their
              subscription plan for the SaaS services at any time. For any kind
              of upgrade or downgrade in subscription, customer has to write to
              admin@antstack.io / customer support team.
            </p>
            <p className="paragraph">
              The chosen method of billing will automatically be charged the new
              subscription rate on the next billing cycle.
            </p>
          </div>
          <div>
            <h3 className="paragraph">Contact Us</h3>
            <p className="paragraph-highlight">
              By email{" "}
              <span>
                <a href="mailto:admin@antstack.io" className="link-style">
                  admin@antstack.io{" "}
                </a>
              </span>
              By visiting this page on our website{" "}
              <span>
                <a href="https://www.bizos.io/" className="link-style">
                  https://www.bizos.io/
                </a>
              </span>{" "}
            </p>
            <p>Collecting and Using Your Personal Data</p>
          </div>
          <div>
            <h3 className="head-cr">Types of Data Collected</h3>
            <div>
              <p className="paragraph-highlight">Personal Data</p>
              <p className="paragraph">
                While using Our Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. Personally identifiable information may
                include, but is not limited to:
              </p>
              <p className="paragraph">
                <ul>
                  <li>Email address</li>
                  <li>First name and last name</li>
                  <li>Phone number</li>
                  <li>Address, State, Province, ZIP/Postal code, City</li>
                </ul>
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">Usage Data</p>
              <p className="paragraph">
                <ul>
                  <li>
                    Usage Data has collected automatically when using the
                    Service.
                  </li>
                  <li>
                    Usage Data may include information such as Your Device’s
                    Internet Protocol address (e.g. IP address), browser type,
                    browser version, the pages of our Service that You visit,
                    the time and date of Your visit, the time spent on those
                    pages, unique device identifiers and other diagnostic data.
                  </li>
                  <li>
                    When You access the Service by or through a mobile device,
                    We may collect certain information automatically, including,
                    but not limited to, the type of mobile device You use, Your
                    mobile device unique ID, the IP address of Your mobile
                    device, Your mobile operating system, the type of mobile
                    Internet browser You use, unique device identifiers and
                    other diagnostic data.
                  </li>
                  <li>
                    We may also collect information that Your browser sends
                    whenever You visit our Service or when You access the
                    Service by or through a mobile device.
                  </li>
                </ul>
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">
                Information from Third-Party Social Media Services
              </p>
              <p className="paragraph">
                The Company allows You to create an account and log in to use
                the Service through the following Third-party Social Media
                Services:
                <ul>
                  <li>Google</li>
                  <li>Facebook</li>
                  <li>Twitter</li>
                  <li>Whatsapp</li>
                </ul>
              </p>
              <p className="paragraph">
                If You decide to register through or otherwise grant us access
                to a Third-Party Social Media Service, We may collect Personal
                data that is already associated with Your Third-Party Social
                Media Service’s account, such as Your name, Your email address,
                Your activities or Your contact list associated with that
                account. You may also have the option of sharing additional
                information with the Company through Your Third-Party Social
                Media Service’s account. If You choose to provide such
                information and Personal Data, during registration or otherwise,
                You are giving the Company permission to use, share, and store
                it in a manner consistent with this Privacy Policy.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">
                Tracking Technologies and Cookies
              </p>
              <p className="paragraph">
                We use Cookies and similar tracking technologies to track the
                activity on Our Service and store certain information. Tracking
                technologies used are beacons, tags, and scripts to collect and
                track information and to improve and analyze Our Service. The
                technologies We use may include:
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">Cookies or Browser Cookies</p>
              <p className="paragraph">
                A cookie is a small file placed on Your Device. You can instruct
                Your browser to refuse all Cookies or to indicate when a Cookie
                is being sent. However, if You do not accept Cookies, You may
                not be able to use some parts of our Service. Unless you have
                adjusted Your browser setting so that it will refuse Cookies,
                our Service may use Cookies.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">Flash Cookies</p>
              <p className="paragraph">
                Certain features of our Service may use local stored objects (or
                Flash Cookies) to collect and store information about Your
                preferences or Your activity on our Service. Flash Cookies are
                not managed by the same browser settings as those used for
                Browser Cookies. For more information on how You can delete
                Flash Cookies, please read “Where can I change the settings for
                disabling, or deleting local shared objects?” available at{" "}
                <span>
                  <a
                    href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects
                flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects"
                    className="link-style"
                  >
                    https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects
                    flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects
                  </a>
                </span>
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">Web Beacons</p>
              <p className="paragraph">
                Certain sections of our Service and our emails may contain small
                electronic files known as web beacons (also referred to as clear
                gifs, pixel tags, and single-pixel gifs) that permit the
                Company, for example, to count users who have visited those
                pages or opened an email and for other related website
                statistics (for example, recording the popularity of a certain
                section and verifying system and server integrity). Cookies can
                be “Persistent” or “Session” Cookies. Persistent Cookies remain
                on Your personal computer or mobile device when You go offline,
                while Session Cookies are deleted as soon as You close Your web
                browser. Learn more about cookies: What Are Cookies?. We use
                both Session and Persistent Cookies for the purposes set out
                below:
              </p>
            </div>{" "}
            <div>
              <p className="paragraph-highlight">
                Necessary / Essential Cookies Type
              </p>
              <p className="paragraph">
                Session Cookies Administered by Us Purpose: These cookies are
                essential to provide You with services available through the
                Website and to enable You to use some of its features. They help
                to authenticate users and prevent fraudulent use of user
                accounts. Without these Cookies, the services that You have
                asked for cannot be provided, and We only use these Cookies to
                provide You with those services.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">
                Cookies Policy / Notice Acceptance Cookies
              </p>
              <p className="paragraph">
                Type: Persistent Cookies Administered by: Us Purpose: These
                Cookies identify if users have accepted the use of cookies on
                the Website.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">Functionality Cookies</p>
              <p className="paragraph">
                Type: Persistent Cookies Administered by: Us{" "}
                <span className="paragraph-highlight">Purpose •</span> - These
                cookies allow us to remember choices You make when You use the
                Website, such as remembering your login details or language
                preference. The purpose of these Cookies is to provide You with
                a more personal experience and to avoid You having to re-enter
                your preferences every time You use the Website.
              </p>
              <p className="paragraph">
                For more information about the cookies we use and your choices
                regarding cookies, please visit our Cookies Policy or the
                Cookies section of our Privacy Policy.
              </p>
            </div>
            <div>
              <h3 className="head-cr">Use of Your Personal Data</h3>
              <p className="paragraph">
                The Company may use Personal Data for the following purposes:
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">
                To provide and maintain our Service
              </p>
              <p className="paragraph">
                - Including to monitor the usage of our Service.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">To manage Your Account</p>
              <p className="paragraph">
                - To manage Your registration as a user of the Service. The
                Personal Data You provide can give You access to different
                functionalities of the Service that are available to You as a
                registered user.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">
                For the performance of a contract
              </p>
              <p className="paragraph">
                - The development, compliance and undertaking of the purchase
                contract for the products, items or services You have purchased
                or of any other contract with Us through the Service. To contact
                You: To contact You by email, telephone calls, SMS, or other
                equivalent forms of electronic communication, such as a mobile
                application’s push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">To provide You</p>
              <p className="paragraph">
                - The development, compliance and undertaking of the purchase
                contract for the products, items or services You have purchased
                or of any other contract with Us through the Service. To contact
                You: To contact You by email, telephone calls, SMS, or other
                equivalent forms of electronic communication, such as a mobile
                application’s push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">To provide You</p>
              <p className="paragraph">
                - With news, special offers and general information about other
                goods, services and events which we offer that are similar to
                those that you have already purchased or enquired about unless
                You have opted not to receive such information.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">To manage Your requests</p>
              <p className="paragraph">
                - To attend and manage Your requests to Us.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">For business transfers</p>
              <p className="paragraph">
                - We may use Your information to evaluate or conduct a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of Our assets, whether as
                a going concern or as part of bankruptcy, liquidation, or
                similar proceeding, in which Personal Data held by Us about our
                Service users is among the assets transferred.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">For other purposes</p>
              <p className="paragraph">
                - We may use Your information for other purposes, such as data
                analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns and to evaluate and
                improve our Service, products, services, marketing and your
                experience.
              </p>
              <p className="paragraph">
                We may share Your personal information in the following
                situations: With Service Providers We may share Your personal
                information with Service Providers to monitor and analyze the
                use of our Service, to contact You. For business transfers
              </p>
              <p className="paragraph">
                - We may share or transfer Your personal information in
                connection with, or during negotiations of, any merger, sale of
                Company assets, financing, or acquisition of all or a portion of
                Our business to another company.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">With Affiliates</p>
              <p className="paragraph">
                - We may share Your information with Our affiliates, in which
                case we will require those affiliates to honor this Privacy
                Policy. Affiliates include Our parent company and any other
                subsidiaries, joint venture partners or other companies that We
                control or that are under common control with Us.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">With business partners</p>
              <p className="paragraph">
                - We may share Your information with Our business partners to
                offer You certain products, services or promotions.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">With other users</p>
              <p className="paragraph">
                - When You share personal information or otherwise interact in
                the public areas with other users, such information may be
                viewed by all users and may be publicly distributed outside. If
                You interact with other users or register through a Third-Party
                Social Media Service, Your contacts on the Third-Party Social
                Media Service may see Your name, profile, pictures and
                description of Your activity. Similarly, other users will be
                able to view descriptions of Your activity, communicate with You
                and view Your profile.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">With Your consent</p>
              <p className="paragraph">
                - We may disclose Your personal information for any other
                purpose with Your consent.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">
                Retention of Your Personal Data
              </p>
              <p className="paragraph">
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of Our Service, or We
                are legally obligated to retain this data for longer time
                periods.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">
                Transfer of Your Personal Data
              </p>
              <p className="paragraph">
                Your information, including Personal Data, is processed at the
                Company’s operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction. Your consent to
                this Privacy Policy followed by Your submission of such
                information represents Your agreement to that transfer. The
                Company will take all steps reasonably necessary to ensure that
                Your data is treated securely and in accordance with this
                Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                personal information.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">Business Transactions</p>
              <p className="paragraph">
                If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">Law enforcement</p>
              <p className="paragraph">
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency).
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">Other legal requirements</p>
              <p className="paragraph">
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to:
                <ul>
                  <li>Comply with a legal obligation</li>
                  <li>
                    Protect and defend the rights or property of the Company
                  </li>
                  <li>
                    Prevent or investigate possible wrongdoing in connection
                    with the Service
                  </li>
                  <li>
                    Protect the personal safety of Users of the Service or the
                    public
                  </li>
                  <li>Protect against legal liability</li>
                </ul>
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">
                Security of Your Personal Data
              </p>
              <p className="paragraph">
                The security of Your Personal Data is important to Us, but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While We strive to
                use commercially acceptable means to protect Your Personal Data,
                We cannot guarantee its absolute security.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">Children’s Privacy</p>
              <p className="paragraph">
                Our Service does not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                anyone under the age of 13. If You are a parent or guardian and
                You are aware that Your child has provided Us with Personal
                Data, please contact Us. If We become aware that We have
                collected Personal Data from anyone under the age of 13 without
                verification of parental consent, We take steps to remove that
                information from Our servers.
              </p>
              <p className="paragraph">
                If We need to rely on consent as a legal basis for processing
                Your information and Your country requires consent from a
                parent, We may require Your parent’s consent before We collect
                and use that information.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">Links to Other Websites</p>
              <p className="paragraph">
                Our Service may contain links to other websites that are not
                operated by Us. If You click on a third party link, You will be
                directed to that third party’s site. We strongly advise You to
                review the Privacy Policy of every site You visit. We have no
                control over and assume no responsibility for the content,
                privacy policies or practices of any third party sites or
                services.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">
                Changes to this Privacy Policy
              </p>
              <p className="paragraph">
                We may update Our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                this page. We will let You know via email and/or a prominent
                notice on Our Service, prior to the change becoming effective
                and update the “Last updated” date at the top of this Privacy
                Policy.
              </p>
              <p className="paragraph">
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>
            </div>
            <div>
              <p className="paragraph-highlight">Contact Us</p>
              <p className="paragraph-highlight">
                By email{" "}
                <span>
                  <a href="mailto:admin@antstack.io" className="link-style">
                    admin@antstack.io{" "}
                  </a>
                </span>
                By visiting this page on our website{" "}
                <span>
                  <a href="https://www.bizos.io/" className="link-style">
                    https://www.bizos.io/
                  </a>
                </span>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-footer">
        <Footer />
      </div>
    </div>
  );
}

export default CancellationAndRefund;
