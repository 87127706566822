import React from "react";
import Body from "./body/Body";
import Content from "./content/Content";
import Footer from "./footer/Footer";
import Navbar from "./navbar/Navbar";
import "./Main.css";
import ChatBot from "./chat-bot/ChatBot";
import Pricing from "./pricing/Pricing";
import Faq from "../faq/Faq";

const Main = () => {
  return (
    <div className="ld-parent">
      <div className="ld-1st-row">
        <Navbar />
        <Content />
      </div>

      <div className="ld-2nd-row">
        <Body />
        <ChatBot />
      </div>

      <div className="ld-3rd-row">
        <Pricing />
        <Faq />
      </div>

      <div className="ld-4th-row">
        <Footer />
      </div>
    </div>
  );
};

export default Main;
