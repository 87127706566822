import React from "react";
import "./Footer.css";
import bizLogo from "../../images/bizLg.svg";
import twitterLogo from "../../images/Icon-1.svg";

import linkedInLogo from "../../images/Icon.svg";

import Heart from "../../images/Heart.svg";
import ant from "../../images/antlogo.svg";
import copyright from "../../images/copyright.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const goToQuestions = () => {
    if (window.location.pathname === "/") {
      document.getElementById("faqs")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else {
      navigate("/");
    }
  };

  const goToPricing = () => {
    if (window.location.pathname === "/") {
      document.getElementById("pricing")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else {
      navigate("/");
    }
  };

  return (
    <div className="ft-parent">
      <div className="ft-child child-1">
        <div className="ft-logo-head">
          <img src={bizLogo} className="ft-bz-logo" alt="" />
          <h1>BizOS</h1>
        </div>

        <div className="ft-ant-message">
          <p>
            Made with <img src={Heart} className="ft-heart" alt="heart" /> by{" "}
            <a
              href="https://www.antstack.com/?utm_source=mainwebsite&utm_medium=click&utm_campaign=BizOS&utm_id=babylink"
              target="_blank"
              rel="noreferrer"
              className="ft-ant-logo-link"
            >
              <img src={ant} className="ft-ant" alt="" />
              <strong>
                antstack
                <img src={copyright} className="ft-copyright-logo" alt="" />
              </strong>
            </a>{" "}
          </p>
        </div>
        <div className="ft-socials">
          <div>
            <a
              href="https://twitter.com/biz_OS"
              target="_blank"
              rel="noreferrer"
              className="ft-social-inst"
            >
              <img src={twitterLogo} className="ft-media-img" alt="" />
            </a>
          </div>

          <div>
            <a
              href="https://www.linkedin.com/showcase/82532660/"
              target="_blank"
              rel="noreferrer"
              className="ft-social-inst"
            >
              <img src={linkedInLogo} className="ft-media-img" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-items" id="footer-items">
        <div className=" child-2">
          <a
            href="mailto:products@antstack.io"
            target="_blank"
            rel="noreferrer"
            className="ft-contact"
          >
            <p>Contact Us</p>
          </a>
        </div>
        <div className=" child-3">
          <a href="/terms-and-conditions" className="ft-contact">
            <p>Terms & Conditions</p>
          </a>
        </div>
        <div className=" child-8">
          <p className="ft-contact" onClick={goToPricing}>
            Pricing
          </p>
        </div>
        <div className=" child-4">
          <a href="/cancellation-and-refund-policy" className="ft-contact">
            <p>Cancellation and Refund</p>
          </a>
        </div>
        <div className="child-5">
          <a href="/privacy-policy" className="ft-contact">
            <p>Privacy Policy</p>
          </a>
        </div>
        <div className=" child-6">
          <p className="ft-contact" onClick={goToQuestions}>
            FAQ's
          </p>
        </div>
      </div>
      <div className=" child-7">
        <p className="ft-copyright">
          © 3500 S DuPont Hwy, Dover, Kent 19901, Delaware, USA.
        </p>
      </div>
    </div>
  );
};

export default Footer;
