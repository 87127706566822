import React, { useState, useEffect } from 'react'
import './CountDown.css'

const CountDown = () => {

    const [days, setDays] = useState()
    const [hours, setHours] = useState()
    const [minutes, setMinutes] = useState()
    const [seconds, setSeconds] = useState()

    let interval

    const startTimer = () => {
        const countdownDate = new Date('August 31, 22').getTime()

        interval = setInterval(() => {
            const today = new Date().getTime()
            const timeRemaining = countdownDate - today

            const totalDays = Math.floor(timeRemaining / (24 * 60 * 60 * 1000))

            const totalHours = Math.floor(
                (timeRemaining % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
            )
            const totalMinutes = Math.floor(
                (timeRemaining % (60 * 60 * 1000)) / (60 * 1000)
            )
            const totalSeconds = Math.floor(
                (timeRemaining % (60 * 1000)) / (1000)
            )

            if(timeRemaining < 0) {
                clearInterval(interval.current)
            } else {
                setDays(addZero(totalDays, 2))
                setHours(addZero(totalHours, 2))
                setMinutes(addZero(totalMinutes, 2))
                setSeconds(addZero(totalSeconds, 2))
            }
        })
    }

    const addZero = (number, minLength) => {
        const numberString = number.toString()

        if(numberString.length >= minLength) return numberString
        return '0'.repeat(minLength - numberString.length) + numberString
    }

    useEffect(() => {
        startTimer()
    }, [])

    return (
        <div className='timer-parent' >
            <div className='countdown'>
                <h2 className='timer'>{days}</h2>
                <p className='timer-text'>DAYS</p>
            </div>
            <div className='countdown'>
                <h2 className='timer'>{hours}</h2>
                <p className='timer-text'>HRS</p>
            </div>
            <div className='countdown'>
                <h2 className='timer'>{minutes}</h2>
                <p className='timer-text'>MIN</p>
            </div>
            <div className='countdown'>
                <h2 className='timer'>{seconds}</h2>
                <p className='timer-text'>SEC</p>
            </div>
        </div>
    )
}

export default CountDown
