import React, { useState } from "react";
import "./Faq.css";
import bos_plus from "../images/bos_plus.svg";
import bos_minus from "../images/bos_minus.svg";
import vector1 from "../images/vector1.svg";
import vector2 from "../images/vector2.svg";
import vector3 from "../images/vector3.svg";
import vector4 from "../images/vector4.svg";
import { FaqData } from "./FaqData";

const Faq = () => {
  const [answer, setAnswer] = useState(false);

  const handleAnswer = (item) => {
    setAnswer(item);
  };

  const backToQuestion = () => {
    setAnswer(false);
  };

  return (
    <>
      <div className="fq-parent">
        <h1 className="fq-head">Frequently Asked Questions</h1>

        {FaqData?.map((item) => {
          return (
            <div className="questions-logo-wrapper" id="faqs">
              <div className="qns-parent">
                <p className="faq-questions">{item.question}</p>
                {answer === item.id && (
                  <p className="faq-answers">{item.answer}</p>
                )}
                <hr className="faq-hr-line" />
              </div>

              {answer !== item.id ? (
                <img
                  src={bos_plus}
                  alt="plus"
                  className="plus-icon"
                  value={item.id}
                  onClick={() => {
                    handleAnswer(item.id);
                  }}
                />
              ) : (
                <img
                  src={bos_minus}
                  alt="plus"
                  className="plus-icon"
                  onClick={backToQuestion}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="qns-btn-wrap">
        <p className="para-faq">Still Have Questions?</p>
        <a
          style={{ color: "#ffffff", textDecoration: "none" }}
          href="mailto: products@antstack.io"
        >
          <button className="btn-faq">Write to us</button>
        </a>
      </div>
      <img src={vector1} alt="vector" className="vector1" />
      <img src={vector2} alt="vector" className="vector2" />
      <img src={vector3} alt="vector" className="vector3" />
      <img src={vector4} alt="vector" className="vector4" />
    </>
  );
};

export default Faq;
