import React from 'react'
import './Footer.css'
import Heart from '../../images/Heart.svg'
import AntstackLogo from '../../images/AntstackLogo.svg'

const Footer = () => {
  return (
    <div className='footer'>
        <p className='footer-text'>Made with <img src={Heart} alt='a-heart'/> Love by</p>
        <a href='https://www.antstack.io/?utm_source=mainwebsite&utm_medium=click&utm_campaign=HooChat&utm_id=babylink' target='_blank' >
            <img className='antstack-logo' src={AntstackLogo} alt='Antstack-logo' />
        </a>
    </div>
  )
}
  
export default Footer