import React from "react";
import "./TermsAndConditions.css";
import terms from "../../images/terms.svg";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

function TermsAndCondition() {
  return (
    <div className="master">
      <div className="logo-head">
        <Navbar />
        <div className="intro">
          <div className="intro-part2">
            <h2 className="privacy-policy-text">Terms & Conditions</h2>
          </div>
          <div className="intro-part1">
            <img src={terms} alt="terms" className="terms-img" />
          </div>
        </div>
      </div>
      <div className="body">
        <div className="content-wrapper-terms">
          <div>
            <h3>BizOs ORDER FORM</h3>
            <p className="paragraph">
              <span className="paragraph-highlight">Services:</span>Real time
              Revenue Tracking, Sales Forecast, Project Details and Profit &
              Loss (the "Services").
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">Initial Service Term:</span>{" "}
              1 month
            </p>
            <p className="paragraph">
              This Services Order Form is a binding, legal agreement between the
              Company that accesses and uses the Services and AntStack
              Technologies Private Limited (trading as BizOs) (the “Company”,
              “us”, or “our”) for the provision of the Services, and is subject
              to the Terms of Service set out below.{" "}
            </p>
            <p className="paragraph">
              By accessing and using the Services , you represent that (1) you
              have read, understand, and agree to be bound by the terms of use,
              (2) you have the authority to enter into the terms of use
              personally or on behalf of the Company, and to bind that Company
              to the terms of use.
            </p>
            <p className="paragraph">
              If you subscribe to the services for a term (the “Initial Term”),
              then the terms will be automatically renewed for additional
              periods of the same duration as the Initial Term at Company’s
              then-current fee for such services unless you opt out of the
              auto-renewal.
            </p>
          </div>
          <div>
            <h3 className="head-tc">TERMS OF SERVICE</h3>
            <p className="paragraph-highlight">
              Last Updated 09th September 2022
            </p>
            <div>
              <p className="sub-head">1. SAAS SERVICES AND SUPPORT</p>
              <p className="paragraph">
                1.1. Subject to the Services Order Form above and these Terms of
                Service (including any Exhibits to it) as amended from time to
                time), Company will use commercially reasonable efforts to
                provide the Services to the Customer during the Term (as defined
                in Section 5.1 below).
              </p>
              <p className="paragraph">
                1.2. As part of the registration process, Customer will identify
                an administrative username and password for Customer’s Company
                account. Company reserves the right to refuse registration of,
                or cancel passwords it deems inappropriate.
              </p>
              <p className="paragraph">
                1.3. Customer recognises that Company is always innovating and
                finding ways to improve the Services with new features and
                services. Customer therefore agrees that the Services may change
                from time to time and no warranty, representation or other
                commitment is given in relation to the continuity of any
                functionality of the Service.
              </p>
              <p className="paragraph">
                1.4. Subject to the terms hereof, Company will provide Customer
                with reasonable technical support services in accordance with
                the terms set forth in Exhibit A.
              </p>
              <p className="paragraph"></p>
              <p className="paragraph"></p>
            </div>
            <div>
              <p className="sub-head">2. RESTRICTIONS AND RESPONSIBILITIES</p>
              <p className="paragraph">
                2.1. Company hereby grants to Customer a non-exclusive,
                non-transferable, non-sublicensable right to access and use the
                Services during the Term in accordance with the Agreement,
                solely for Customer's internal business purposes.
              </p>
              <p className="paragraph">
                2.2 Customer will not, directly or indirectly (except to the
                extent permitted by any applicable law which is incapable of
                exclusion by the agreement of the parties): reverse engineer,
                decompile, disassemble or otherwise attempt to discover the
                source code, object code or underlying structure, ideas,
                know-how or algorithms relevant to the Services or any software,
                documentation or data related to the Services (“Software”);
                modify, translate, or create derivative works based on the
                Services or any Software(except to the extent expressly
                permitted by Company or authorised within the Services);
                license, sell, rent, lease, transfer, assign, distribute,
                display, disclose or otherwise commercially exploit the Services
                and any Software (except to the extent expressly permitted by
                Company or authorised within the Services); use the Services or
                any Software for timesharing or service bureau purposes or
                otherwise for the benefit of a third party; or remove any
                proprietary notices or labels. With respect to any Software that
                is distributed or provided to Customer for use on Customer
                premises or devices, Company hereby grants Customer a
                non-exclusive, non-transferable, non-sublicensable licence to
                use such Software during the Term only in connection with the
                Services.
              </p>
              <p className="paragraph">
                2.3. Customer shall comply with all applicable technology
                control and export laws and regulations.{" "}
              </p>
              <p className="paragraph">
                2.4. Customer represents, covenants, and warrants that Customer
                will use the Services only in compliance with the terms and
                conditions of the Agreement, and all applicable laws and
                regulations. Customer hereby agrees to indemnify and hold
                harmless Company against any damages, losses, liabilities,
                settlements and expenses (including without limitation costs and
                attorneys’ fees) in connection with any claim or action that
                arises from any actual or alleged breach of the terms and
                conditions of the Agreement, and/or any applicable laws and
                regulations, or otherwise from Customer’s use of Services.
                Although Company has no obligation to monitor Customer’s use of
                the Services, Company may do so and may prohibit any use of the
                Services it believes may be (or alleged to be) in violation of
                the Agreement.
              </p>
              <p className="paragraph">
                2.5. Customer shall be responsible for obtaining and maintaining
                any equipment and ancillary services needed to connect to,
                access or otherwise use the Services, including, without
                limitation, modems, hardware, servers, software, operating
                systems, networking, web servers and the like (collectively,
                “Equipment”). Customer shall also be responsible for maintaining
                the security of the Equipment, Customer account, passwords
                (including but not limited to administrative and user passwords)
                and files, and for all uses of Customer account or the Equipment
                with or without Customer’s knowledge or consent.
              </p>
            </div>
            <div>
              <p className="sub-head">
                {" "}
                3. CONFIDENTIALITY; PROPRIETARY RIGHTS
              </p>
              <p className="paragraph">
                3.1. Each party undertakes that it shall not at any time during
                this Agreement, and for a period of two years after termination
                or expiry of this Agreement, disclose to any person any
                confidential information concerning the business, affairs,
                customer, clients or suppliers or the other party or of any
                member of the group of companies to which the other party
                belongs, except as permitted by clause 3.2 below.
              </p>
              <p className="paragraph">
                {" "}
                3.2 Not withstanding clause 3.1, each party may disclose the
                other party's confidential information:
                <ul>
                  <li>
                    (a) to its employees, officers, representatives,
                    contractors, subcontractors or advisers who need to know
                    such information for the purposes of exercising the party's
                    rights or carrying out its obligations under or in
                    connection with this Agreement. Each party shall ensure that
                    its employees, officers, representatives, contractors,
                    subcontractors or advisers to whom it discloses the other
                    party's confidential information comply with this clause 3;
                    and
                  </li>
                  <li>
                    (b) as may be required by law, a court of competent
                    jurisdiction or any governmental or regulatory authority.
                  </li>
                </ul>
              </p>
              <p className="paragraph">
                3.3 No party shall use any other party's confidential
                information for any purpose other than to exercise its rights
                and perform its obligations under or in connection with this
                Agreement.
              </p>
              <p className="paragraph">
                3.4. Customer shall own and retain all right, title and interest
                in and to the Customer Data, and shall have sole responsibility
                for the legality, reliability, integrity, accuracy and quality
                of the Customer Data.
              </p>
              <p className="paragraph">
                3.5. Company shall own and retain all right, title and interest
                in and to (a) the Services and Software, all improvements,
                enhancements or modifications thereto,(b) any software,
                applications, inventions or other technology developed in
                connection with Services or support, and (c) all intellectual
                property rights related to any of the foregoing.
              </p>
              <p className="paragraph">
                3.6. Not withstanding anything to the contrary, Company shall
                have the right to collect and analyse data and other information
                relating to the provision, use and performance of various
                aspects of the Services and related systems and technologies
                (including, without limitation, information concerning Customer
                Data and data derived therefrom), and Company will be free
                (during and after the Term) to (i) use such information for the
                purpose of providing the Services; (ii) use such information and
                data to improve and enhance the Services and for other
                development, diagnostic and corrective purposes in connection
                with the Services and other Company offerings, and (iii)
                disclose such data solely in aggregate or other de-identified
                form in connection with its business. No rights or licences are
                granted except as expressly set forth herein.
              </p>
            </div>
            <div>
              <p className="sub-head">4. PAYMENT OF FEES</p>
              <p className="paragraph">
                4.1. Customer will pay Company the then applicable Service Fees
                . Company reserves the right to change the Service Fees or
                applicable charges and to institute new charges and Service Fees
                at the end of the Initial Service Term or then-current renewal
                term, upon thirty (30) days prior notice to Customer (which may
                be sent by email). If Customer believes that Company has billed
                Customer incorrectly, Customer must contact Company no later
                than 60 days after the closing date on the first billing
                statement in which the error or problem appeared, in order to
                receive an adjustment or credit. Inquiries should be directed to
                Company’s customer support department.
              </p>
              <p className="paragraph">
                4.2. Company may choose to bill through an invoice, in which
                case, full payment for invoices issued in any given month must
                be received by Company ten (10) days after the mailing date of
                the invoice. Unpaid amounts are subject to interest at an annual
                rate of 4% over the then-current base lending rate of HDFC Bank
                from time to time on any outstanding balance, or the maximum
                permitted by law, whichever is lower, commencing on the due date
                for payment and continuing until payment is received in full by
                Company, plus all expenses of collection, and may result in
                immediate termination of the Service. Customer shall be
                responsible for all taxes associated with Services other than
                applicable taxes based on Company’s net income.
              </p>
              <p className="paragraph">
                4.3. For the purpose of calculating the Service Fees, the term
                "Additional Revenue" means any revenue generated by Customer
                arising from Customer's use of the Services (prior to the
                addition of taxes).
              </p>
            </div>
            <div>
              <p className="sub-head">5. TERM AND TERMINATION</p>
              <p className="paragraph">
                5.1. Subject to earlier termination as provided below, the
                Agreement is for the Initial Service Term, and shall be
                automatically renewed for additional periods of the same
                duration as the Initial Service Term (collectively, the
                “Term”),unless either party gives the other party at least ten
                (10) days’ notice inwriting to terminate the Agreement at the
                end of the then current term. A reason for this termination does
                not need to be given.
              </p>
              <p className="paragraph">
                5.2. Without prejudice to any other rights or remedies available
                to it, Company may, without liability, immediately disable
                Customer's account for the Services or prevent access by
                Customer to the Services for any breach by Customer of this
                Agreement.
              </p>
              <p className="paragraph">
                5.3. In addition to any other remedies it may have, either party
                may also terminate the Agreement immediately on written notice
                (or without notice in the case of non-payment), if the other
                party (i) materially breaches any of the terms or conditions of
                the Agreement which breach is irremediable or (if such breach is
                remediable) fails to remedy the breach within ten (10) days of
                being notified in writing to do so, or (ii) is unable to pay its
                debts (within the meaning of section 123 of THE INSOLVENCY AND
                BANKRUPTCY CODE, 2016) or becomes insolvent, or is subject to an
                order or a resolution for its liquidation, administration,
                winding-up or dissolution (otherwise than for the purposes of a
                solvent amalgamation or reconstruction), or has an
                administrative or other receiver, manager, trustee, liquidator,
                administrator or similar officer appointed over all or any
                substantial part of its assets, or enters into or proposes any
                composition or arrangement with its creditors generally, or is
                subject to any analogous event or proceeding in any applicable
                jurisdiction. Customer will pay in full for the Services up to
                and including the last day on which the Services are provided.
                Upon any termination (i) all licences granted under the
                Agreement shall immediately terminate and Customer's right to
                access and use the Services will end, and (ii) (subject to the
                terms and conditions of the DPA) Company will make all Customer
                Data available to Customer for electronic retrieval for a period
                of thirty (30) days, but thereafter, Company may, but is not
                obligated to, delete stored Customer Data. All sections of the
                Agreement which by their nature should survive termination will
                survive termination, including, without limitation, accrued
                rights to payment, confidentiality obligations, warranty
                disclaimers, and limitations of liability.
              </p>
            </div>
            <div>
              <p className="sub-head">6. WARRANTY AND DISCLAIMER</p>
              <p className="paragraph">
                6.1 Company shall use reasonable efforts consistent with
                prevailing industry standards to maintain the Services in a
                manner which minimises errors and interruptions in the Services
                and shall perform the Services in a professional and workman
                like manner. Services may be temporarily unavailable for
                scheduled maintenance or for unscheduled emergency maintenance,
                either by Company or by third-party providers, or because of
                other causes beyond Company’s reasonable control, but Company
                shall use reasonable efforts to provide advance notice in
                writing or by e-mail of any scheduled service disruption.
              </p>
              <p className="paragraph">
                6.2 Company does not warrant that the Services will be
                uninterrupted or error free; nor does it make any warranty as to
                the results that may be obtained from use of the Services.
                Except as expressly set forth in this section, the services are
                provided “AS IS” and Company disclaims and excludes from the
                Agreement to the fullest extent permitted by applicable law all
                warranties, representations, conditions and all other terms of
                any kind whatsoever, express or implied by statute or common law
                or otherwise, including, but not limited to, implied warranties
                of merchantability and fitness for a particular purpose and
                non-infringement.
              </p>
            </div>
            <div>
              <p className="sub-head">7. LIMITATION OF LIABILITY</p>
              <p className="paragraph">
                7.1 Nothing in the Agreement excludes the liability of either
                party for death or personal injury caused by its negligence, or
                for fraud or fraudulent misrepresentation.
              </p>
              <p className="paragraph">
                7.2 Subject to clause 7.1, Company and its suppliers (including
                but not limited to all equipment and technology suppliers),
                officers, affiliates, representatives, contractors and employees
                shall not be responsible or liable with respect to any subject
                matter of the Agreement or terms and conditions related thereto
                under any contract, tort (including negligence), for breach of
                statutory duty, or otherwise: (a) for error or interruption of
                use of the Services or for loss, alteration, or inaccuracy or
                corruption of data (including Customer Data) or cost of
                procurement of substitute goods, services or technology; (b) for
                any loss (whether direct or indirect) of profits, revenue,
                business, or goodwill; (c) for any indirect, exemplary,
                incidental, special or consequential loss, costs, damages,
                charges or expenses; (d) for any matter beyond Company’s
                reasonable control; or (e) for any amounts that, together with
                amounts associated with all other claims, exceed the fees paid
                by Customer to Company for the Services under the Agreement in
                the 12 months prior to the act that gave rise to the liability,
                in each case, whether or not Company has been advised of the
                possibility of such damages.
              </p>
            </div>
            <div>
              <p className="sub-head">8. MISCELLANEOUS</p>
              <p className="paragraph">
                8.1 Company may use Customer's name, logo, and related trade
                marks in any of Company's publicity or marketing materials for
                the purpose of highlighting that Customer uses the Services, and
                alongside any testimonials that Customer has agreed to give.
              </p>
              <p className="paragraph">
                8.2 Company shall have no liability to Customer under the
                Agreement if it is prevented from or delayed in performing its
                obligations under the Agreement by any act, event or omission
                beyond its control, including (without limitation): strikes,
                lock-outsor other industrial disputes; failure of a utility
                service or transport or telecommunications network; act of God,
                fire, flood, or storm; war, riot, or civil commotion; malicious
                damage; compliance with any law or governmental order, rule,
                regulation or direction; accident; breakdown of machinery;
                ordefault of suppliers or sub-contractors. Company shall notify
                Customer of any such event and (where possible) its expected
                duration.
              </p>
              <p className="paragraph">
                8.3 If any provision of the Agreement is found to be
                unenforceable or invalid, that provision will be limited or
                eliminated to the minimum extent necessary so that the Agreement
                will otherwise remain in full force and effect and enforceable.
              </p>
              <p className="paragraph">
                8.4 If there is an inconsistency between any of the provisions
                in the main body of the Agreement and the Exhibits, the
                provisions in the Exhibits shall prevail to the extent of the
                inconsistency.
              </p>
              <p className="paragraph">
                8.5 The Agreement is not assignable, transferable or
                sublicensable by Customer except with Company’s prior written
                consent. Company may transfer and assign any of its rights and
                obligations under the Agreement without the consent of Customer.
              </p>
              <p className="paragraph">
                8.6 The Agreement(including the Exhibits and any documents
                referred to in it) constitutes the entire agreement between the
                parties and is the complete and exclusive statement of the
                mutual understanding of the parties and supersedes and cancels
                all previous written and oral agreements, communications and
                other understandings relating to the subject matter of the
                Agreement. All waivers and modifications must be in a writing
                signed by both parties, except as otherwise provided herein.
              </p>
              <p className="paragraph">
                8.7 No failure or delay by either party to exercise any right or
                remedy provided under the Agreement shall constitute a waiver of
                that or any other right or remedy, nor shall it prevent or
                restrict the further exercise of that or any other right or
                remedy.
              </p>
              <p className="paragraph">
                8.8 No agency, partnership, joint venture, or employment is
                created as a result of the Agreement and Customer does not have
                any authority of any kind to bind Company in any respect
                whatsoever.
              </p>
              <p className="paragraph">
                8.9 The Agreement does not confer any rights on any person or
                party (other than the parties to the Agreement), under the
                Contracts (Rights of Third Parties) Act 1872 or otherwise.
              </p>
              <p className="paragraph">
                8.10 Applicable laws may require that some of the information or
                communications Company sends to Customer should be in writing.
                When using the Services, Customer accepts that communication
                with Company will mainly be electronic and Company may provide
                information to Customer by posting notices on the Services. All
                notices under the Agreement will be in writing and will be
                deemed to have been duly given when received, if personally
                delivered; when receipt is electronically confirmed, if
                transmitted by facsimile or e-mail; the day after it is sent, if
                sent for next day delivery by recognised overnight delivery
                service; and upon receipt, if sent by certified or registered
                mail, return receipt requested, provided that Company may give
                notice to Customer at either the e-mail or postal address
                Customer provides to Company, or any other way Company deems
                appropriate. The foregoing notice provisions do not apply to the
                termination of the Agreement, or to the service of any
                proceedings or other documents in any legal action or dispute
                resolution.
              </p>
              <p className="paragraph">
                8.11 The Agreement and any dispute or claim arising out of or in
                connection with it or its subject matter or formation (including
                non-contractual disputes or claims) shall be governed by and
                construed in accordance with the law of Bengaluru, Karnataka.
              </p>
              <p className="paragraph">
                8.12 Each party irrevocably agrees that the courts of Bengaluru,
                Karnataka shall have exclusive jurisdiction to settle any
                dispute or claim arising out of or in connection with the
                Agreement or its subject matter or formation (including
                non-contractual disputes or claims).
              </p>
              <p className="paragraph">
                8.13 Company has the right to revise and amend these Terms of
                Service from time to time. Changes to these Terms of Service are
                effective when they are posted on this page, and Customer will
                be subject to the Terms of Service in force at the time that it
                makes use of the Services (unless otherwise agreed by the
                parties in writing).
              </p>
            </div>
          </div>
          <div>
            <h3 className="head-tc">EXHIBIT A</h3>
            <p className="paragraph-highlight">Support Terms</p>
            <p className="paragraph">
              Company will provide Technical Support to Customer via electronic
              mail on weekdays during the hours of 11:00 am IST time through to
              5:00 pm, with the exclusion of Bank Holidays in the INDIA(
              <span className="paragraph-highlight">“Support Hours”</span>).
            </p>
            <p className="paragraph">
              Company will use commercially reasonable efforts to respond to all
              Helpdesk tickets within one(1) business day.
            </p>
          </div>
          <div>
            <h3 className="head-tc">EXHIBIT B</h3>
            <p className="paragraph-highlight">Data Processing Addendum</p>
            <h4 className="head-tc">ANTSTACK DATAPROCESSING ADDENDUM</h4>
          </div>
          <div>
            <p className="sub-head">BACKGROUND</p>
            <p className="paragraph">
              {" "}
              The Customer and AntStack Technologies Private Limited("AntStack",
              "the Company", "we", "us", or "our") entered into the Agreement
              (as defined below) for the provision of the Services (as defined
              in the Agreement) from AntStack to the Customer.
            </p>
            <p className="paragraph">
              In the event that AntStack Processes personal data (each as
              defined below) contained in Customer Data of individuals located
              in INDIA (as defined below), or if the Customer is established in
              INDIA, Data Processing Addendum (the "DPA") shall be supplemental
              to the Agreement and shall apply to the Processing of such
              personal data. In the event of a conflict between any of the
              provisions of this DPA and the provisions of the Agreement, the
              provisions of this DPA shall prevail.
            </p>
            <p className="paragraph">
              Both parties will comply with all applicable requirements of the
              Data Protection Laws (as defined below). This DPA is in addition
              to, and does not relieve, remove or replace, a party's obligations
              under the Data Protection Laws.
            </p>
            <p className="paragraph-highlight">DEFINITIONS</p>
            <p className="paragraph">
              Unless otherwise set out below, each capitalised term in this DPA
              shall have the meaning set out in the Agreement, and the following
              capitalised terms used in this DPA shall be defined as follows:
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">"Agreement"</span> means the
              agreement entered into between the Company and the Customer for
              the provision of the Service, comprising the Order Form and the
              Terms of Service (including the Exhibits to it);
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">"Controller"</span> has the
              meaning given in the Data Protection Laws;
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">
                "Customer Personal Data"
              </span>{" "}
              means the personal data (as defined in the Data Protection Laws)
              described in ANNEX 1 and any other personal data that AntStack
              processes on behalf of the Customer in connection with AntStack's
              provision of the Service;
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">
                "Data Protection Laws"
              </span>{" "}
              means:
              <ul>
                <li>
                  to the extent the INDIA GDPR applies, the law of INDIA or of a
                  part of INDIA which relates to the protection of personal
                  data; and
                </li>
                <li>
                  {" "}
                  to the extent INDIA GDPR applies, the law of the Indian Union
                  or any member state of the Indian Union to which the Customer
                  or AntStack is subject, which relates to the protection of
                  personal data..
                </li>
              </ul>
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">"Data Subject"</span> has
              the meaning given in the Data Protection Laws;
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">“INDIA GDPR”</span> means
              the Information Technology Act, 2000 (IT Act) and Information
              Technology (Reasonable Security Practices and Procedures and
              Sensitive Personal Data or Information) Rules, 2011;
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">
                "INDIA Economic Area" or "INDIA"
              </span>{" "}
              means the Member States of the INDIA Union together with INDIA
              Union Territory;
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">"Processing"</span> has the
              meaning given in the Data Protection Laws, and "Process" shall be
              interpreted accordingly; Data Protection Laws GDPR;
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">"Security Incident"</span>{" "}
              means any accidental or unlawful destruction, loss, alteration,
              unauthorised disclosure of, or access to, any Customer Personal
              Data;
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">
                "Standard Contractual Clauses"
              </span>{" "}
              means the Standard Contractual Clauses (processors) approved by
              European Commission Decision on 4 June 2021 or any subsequent
              version thereof released by the European Commission (which will
              automatically apply);
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">"Subprocessor"</span> means
              any Processor engaged by AntStack that agrees to receive from
              AntStack and Process any Customer Personal Data; and
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">"Supervisory"</span> has the
              meaning given to it in section 3(10) (as supplemented by section
              205(4)) of the Data Protection Act2018.
            </p>
          </div>
          <div>
            <p className="sub-head">DATA PROCESSING</p>
            <p className="paragraph">
              <span className="paragraph-highlight">
                Instructions for Data Processing.
              </span>{" "}
              AntStack will only Process Customer Personal Data in accordance
              with the Customer's instructions, unless Processing is required by
              Data Protection Laws to which AntStack is subject, in which case
              AntStack shall, to the extent permitted by Data Protection Laws,
              inform the Customer of that legal requirement before Processing
              that Customer Personal Data. The Agreement (subject to any changes
              to the Services agreed between the Parties), including this DPA,
              shall be the Customer's complete and final instructions to
              AntStack in relation to the processing of Customer Personal Data.
            </p>
            <p className="paragraph">
              Processing outside the scope of this DPA or the Agreement will
              require prior written agreement between the Customer and AntStack
              on additional instructions for Processing.
            </p>
            <p className="paragraph">
              Required consents. Where required by applicable Data Protection
              Laws, the Customer warrants that it will ensure that it has
              obtained/will obtain all necessary consents for the Processing of
              Customer Personal Data by AntStack in accordance with the
              Agreement, and agrees to indemnify AntStack for any direct losses
              arising out of a breach of this clause.
            </p>
          </div>
          <div>
            <p className="sub-head"> TRANSFER OF PERSONAL DATA</p>
            <p className="paragraph">
              <span className="paragraph-highlight">
                Authorised Subprocessors.
              </span>{" "}
              The Customer agrees that AntStack may use "Companies" as
              Subprocessors to Process Customer Personal Data, together with
              additional subcontractors when required from time to time, which
              the Customer hereby approves in advance.
            </p>
            <p className="paragraph">
              Save as set out in clauses 4.1, AntStack shall not permit, allow
              or otherwise facilitate Subprocessors to Process Customer Personal
              Data unless AntStack enters into a written agreement with the
              Subprocessor which imposes the same obligations on the
              Subprocessor with regards to their Processing of Customer Personal
              Data as are imposed on AntStack under this DPA.
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">
                Liability of Subprocessors.
              </span>{" "}
              AntStack shall at all times remain responsible for compliance with
              its obligations under the DPA and will be liable to the Customer
              for the acts and omissions of any Subprocessor as if they were the
              acts and omissions of AntStack.
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">
                Transfers of Personal Data.
              </span>{" "}
              To the extent that the Processing of Customer Personal Data by
              AntStack involves the export of such Customer Personal Data to a
              third party to a country or territory outside INDIA, other than
              (i) a country or territory ensuring an adequate level of
              protection for the rights and freedoms of Data Subjects in
              relation to the Processing of personal data as determined by the
              Indian Commission, or (ii) where the third party is a member of a
              compliance scheme recognised as offering adequate protection for
              the rights and freedoms of Data Subjects as determined by the
              Indian Commission, such export shall be governed by the Standard
              Contractual Clauses between the Customer as exporter and such
              third party as importer. For this purpose, the Customer appoints
              AntStack as its agent with the authority to complete and enter
              into the Standard Contractual Clauses as agent for the Customer on
              its behalf for this purpose.
            </p>
            <p className="paragraph">
              In the event of any conflict between any terms and conditions of
              the Standard Contractual Clauses and this DPA, the Standard
              Contractual Clauses shall prevail.
            </p>
          </div>
          <div>
            <p className="sub-head">
              DATA SECURITY, AUDITS AND SECURITY NOTIFICATIONS
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">
                AntStack Security Obligations.
              </span>{" "}
              Taking into account the state of the art, the costs of
              implementation and the nature, scope, context and purposes of
              Processing, as well as the risk of varying likelihood and severity
              for the rights and freedoms of natural persons, AntStack shall
              implement appropriate technical and or organisational measures to
              ensure a level of security appropriate to the risk, including the
              measures set out in ANNEX 2.
            </p>
            <p className="paragraph">
              {" "}
              <span className="paragraph-highlight">Security Audits.</span> The
              Customer may, upon reasonable notice, audit(by itself or using
              independent third party auditors) AntStack’s compliance with the
              security measures set out in this DPA (including the technical and
              organisational measures as set out in ANNEX 2) no more than once
              per year, including by conducting audits of AntStack’s data
              processing facilities. Upon request by the Customer, AntStack
              shall make available all information reasonably necessary to
              demonstrate compliance with this DPA.
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">
                Security Incident Notification.
              </span>{" "}
              If AntStack or any Subprocessor becomes aware of a Security
              Incident, AntStack will (a) notify the Customer of the Security
              Incident within 72 hours of becoming aware of the Security
              Incident, (b) investigate the Security Incident and provide such
              reasonable assistance to the Customer (and any law enforcement or
              regulatory official) as required to investigate the Security
              Incident, and (c) take steps to remedy any non-compliance with
              this DPA.
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">
                AntStack Employees and Personnel.
              </span>{" "}
              AntStack shall treat the Customer Personal Data as the
              Confidential Information of the Customer, and shall ensure that
              any employees or other personnel have agreed in writing to protect
              the confidentiality and security of Customer Personal Data.
            </p>
          </div>
          <div>
            <p className="sub-head">ACCESS REQUESTS AND DATA SUBJECT RIGHTS</p>
            <p className="paragraph">
              <span className="paragraph-highlight">
                Data Subject Requests.
              </span>{" "}
              Save as required (or where prohibited) under applicable law,
              AntStack shall notify the Customer of any request received by
              AntStack or any Subprocessor from a Data Subject in respect of
              their personal data included in the Customer Personal Data, and
              shall not respond to the Data Subject.
            </p>
            <p className="paragraph">
              AntStack shall provide the Customer with the ability to correct,
              delete, block, access, or copy the Customer Personal Data in
              accordance with the functionality of the Service.
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">
                Government Disclosure.
              </span>{" "}
              AntStack shall notify the Customer of any request for the
              disclosure of Customer Personal Data by a governmental or
              regulatory body or law enforcement authority (including any data
              protection supervisory authority) unless otherwise prohibited by
              law or a legally binding order of such body or agency.
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">Data Subject Rights.</span>{" "}
              Where applicable, and taking into account the nature of the
              Processing, AntStack shall use all reasonable endeavours to assist
              the Customer by implementing any other appropriate technical and
              organisational measures, insofar as this is possible, for the
              fulfilment of the Customer’s obligation to respond to requests for
              exercising Data Subject rights laid down in the GDPR.
            </p>
            <p className="paragraph">
              <span className="paragraph-highlight">
                Data Protection Impact Assessment and Prior Consultation.
              </span>{" "}
              To the extent required under applicable Data Protection Laws,
              AntStack shall provide reasonable assistance to the Customer with
              any data protection impact assessments and with any prior
              consultations to any Supervisory Authority of the Customer, in
              each case solely in relation to Processing of Customer Personal
              Data and taking into account the nature of the Processing and
              information available to AntStack.
            </p>
          </div>
          <div>
            <p className="sub-head">TERMINATION</p>
            <p className="paragraph">
              {" "}
              Subject to clause 7.2 below, the Customer may in its absolute
              discretion notify AntStack in writing within thirty (30) days of
              the date of termination of the Agreement to require AntStack to
              delete and procure the deletion of all copies of Customer Personal
              Data Processed by AntStack. AntStack shall, within ninety (90)
              days of the date of termination of the Agreement:
              <ul>
                <li>comply with any such written request; and</li>
                <li>
                  use all reasonable endeavours to procure that its
                  Subprocessors delete all Customer Personal Data Processed by
                  such Subprocessors,
                </li>
                <li>
                  and, where this clause 7.2 applies, AntStack shall not be
                  required to provide a copy of the Customer Personal Data to
                  the Customer.
                </li>
              </ul>
            </p>
            <p className="paragraph">
              AntStack and its Subprocessors may retain Customer Personal Data
              to the extent required by applicable laws and only to the extent
              and for such period as required by applicable laws and always
              provided that AntStack shall ensure the confidentiality of all
              such Customer Personal Data and shall ensure that such Customer
              Personal Data is only Processed as necessary for the purpose(s)
              specified in the applicable laws requiring its storage and for no
              other purpose.
            </p>
          </div>
          <div>
            <h3 className="head paragraph-highlight">ANNEX 1</h3>
            <p className="sub-head">
              Details of the Processing of CUSTOMER Personal Data
            </p>
            <p className="paragraph">
              This ANNEX 1 includes certain details of the processing of
              Customer Personal Data.
            </p>
            <p className="paragraph">
              Subject matter and duration of the Processing of Customer Personal
              Data
            </p>
            <p className="paragraph">
              The subject matter and the duration of the Processing of the
              Customer Personal Data are set out in the Agreement and this DPA.
            </p>
            <p className="paragraph">
              The nature and purpose of the Processing of Customer Personal Data
            </p>
            <p className="paragraph">
              The Customer Personal Data will be subject to the following basic
              Processing activities: transmitting, collecting, storing, and
              analysing data in order to provide the Services to the Customer,
              and any other activities related to the provision of the Services
              or as specified in the Agreement.
            </p>
            <p className="paragraph">
              The types of Customer Personal Data to be Processed
            </p>
            <p className="paragraph">
              The types of Customer Personal Data to be Processed concern the
              following categories of data: names of Customer personnel; contact
              information (including email addresses and telephone numbers) of
              Customer personnel and of end users of services of the Customer;
              online identifiers of end users of services of the Customer.
            </p>
            <p className="paragraph">
              The categories of Data Subject to whom the Customer Personal Data
              relates
            </p>
            <p className="paragraph">
              The categories of Data Subject to whom the Customer Personal Data
              relates concern: employees and other personnel of the Customer.
            </p>
            <p className="paragraph">
              The obligations and rights of the Customer
            </p>
            <p className="paragraph">
              The obligations and rights of the Customer are as set out in the
              Agreement and this DPA.
            </p>
          </div>
          <div>
            <h3 className="head paragraph-highlight">ANNEX 2</h3>
            <p className="sub-head">
              Technical and Organisational Security Measures
            </p>
            <p className="paragraph">
              AntStack maintains internal policies and procedures, or procures
              that its Subprocessors do so, which are designed to:
            </p>
            <p className="paragraph">
              - secure any Customer Personal Data Processed by AntStack against
              accidental or unlawful loss, access or disclosure;
            </p>
            <p className="paragraph">
              - identify reasonably foreseeable and internal risks to security
              and unauthorised access to the Customer Personal Data Processed by
              AntStack;
            </p>
            <p className="paragraph">
              - minimise security risks, including through risk assessment and
              regular testing.
            </p>
            <p className="paragraph">
              AntStack will, and will use reasonable efforts to procure that its
              Subprocessors will, conduct periodic reviews of the security of
              its network and the adequacy of its information security program
              as measured against industry security standards and its policies
              and procedures.
            </p>
            <p className="paragraph-highlight">
              AntStack will, and will use reasonable efforts to procure that its
              Subprocessors periodically will, evaluate the security of its
              network and associated services to determine whether additional or
              different security measures are required respond to new security
              risks or findings generated by the periodic reviews.
            </p>
          </div>
        </div>
      </div>
      <div className="privacy-footer">
        <Footer />
      </div>
    </div>
  );
}

export default TermsAndCondition;
