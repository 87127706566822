import React from "react";
import "./LandingPage.css";
import bOS from "../../images/bOS.svg";
import Group1 from "../../images/Group1.svg";
import Group2 from "../../images/Group2.svg";
import Group3 from "../../images/Group3.png";
import Group4 from "../../images/Group4.png";
import Group5 from "../../images/Group5.png";
import Group6 from "../../images/Group6.png";
import text from "../../images/text.svg";
import Footer from "../footer/Footer";
import CountDown from "../timer/CountDown";
import SignUp from "../signup/SignUp";
import "./LandingPage.css";
// import Airtable from "airtable";

// const base = new Airtable({ apiKey: "keyPy0EpC85xfdBhW" }).base(
//   "app1mzenEUngejC4B"
// );

const LandingPage = () => {
  // const [email, setEmail] = useState("");
  // const [success, setSuccess] = useState("");
  // const [error, setError] = useState("");

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   base("signups").create(
  //     [
  //       {
  //         fields: {
  //           Email: email,
  //           createdAt: new Date(),
  //         },
  //       },
  //     ],
  //     function (err, records) {
  //       if (err) {
  //         setSuccess("");
  //         setError("Unable to submit. Please try again!");
  //         console.error(err);
  //         return;
  //       }
  //       setSuccess(
  //         "Thank you for your interest. We will reach out to you soon!"
  //       );
  //       setEmail("");
  //       setError("");
  //     }
  //   );
  // };

  return (
    <div className="wrapper">
      <div className="content">
        <img className="bOS-txt-img" src={text} alt="title" />
        <CountDown />
        <SignUp />
        <Footer />
      </div>
      <div className="bOS-imageWrap">
        <img className="bOS-img" src={bOS} alt="bOS-img" />
      </div>
      <div>
        <img className="Group1" src={Group1} alt="group1" />
        <img className="Group2" src={Group2} alt="group2" />
        <img className="Group3" src={Group3} alt="group3" />
        <img className="Group4" src={Group4} alt="group4" />
        <img className="Group5" src={Group5} alt="group5" />
        <img className="Group6" src={Group6} alt="group6" />
      </div>
    </div>
  );
};

export default LandingPage;
