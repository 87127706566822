import LandingPage from "./components/home/LandingPage";
import ReactGA from "react-ga4";
import Main from "./landing-features/Main";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./landing-features/privacy-policy/PrivacyPolicy";

import TermsAndCondition from "./landing-features/terms-conditions/TermsAndConditions";
import CancellationAndRefund from "./landing-features/cancellation-refund/CancellationAndRefund";
import Pricing from "./pricing/Pricing";

ReactGA.initialize("G-PV0MH8TSQ8");
ReactGA.send("pageview");

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/coming_soon" element={<LandingPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
          <Route
            path="/cancellation-and-refund-policy"
            element={<CancellationAndRefund />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
