import React from "react";
import Footer from "../landing-features/footer/Footer";

import pricing from "../images/pricing-new.svg";

import "./Pricing.css";

function Pricing() {
  return (
    <div className="ld-parent">
      <div className="Wrapper">
        <div className="head1">
          <div className="pricing-img">
            <img src={pricing} alt="pricing logo" />
          </div>
          <div className="pricing-content">
            <h2 className="pricing">Pricing</h2>
            <div className="content1">
              BizOS is <span className="Greentext">Free</span>
              {""}* for the current (beta) version
            </div>
            <div className="content2">
              *Subject to change after our public release
            </div>
            <div className="contact-content">
              <div className="questions">Still have questions?</div>
              <button className="contactUs">
                <a href="mailto:products@antstack.io" className="bd-get-link">
                  Contact Us
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="ld-4th-row">
        <Footer />
      </div>
    </div>
  );
}

export default Pricing;
