import React from "react";
import "./PrivacyPolicy.css";
import privacy from "../../images/privacy-policy-new.svg";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

const PrivacyPolicy = () => {
  return (
    <div className="master">
      <div className="logo-head">
        <Navbar />

        <div className="head-img-pc">
          <img src={privacy} alt="privacy" className="privacy-img" />
          <h2 className="privacy-policy-text"> Privacy Policy</h2>
        </div>
      </div>
      <div className="body">
        <div className="intro-pc">
          <div className="intro-part2-pc">
            <div className="privacy-intro-pc">
              Welcome to our privacy policy. We respect your privacy and are
              committed to protecting your personal data. This privacy policy
              lets you know how we look after your personal data when you visit
              our website (regardless of where you visit it from) or access our
              services and tells you about your privacy rights and how the law
              protects you.
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div>
            <h3 className="head">1. Important information and who we are</h3>
            <div>
              <h4 className="sub-head">Purpose of this privacy policy</h4>
              <p className="paragraph">
                Aims to give you information on how BizOS collects and processes
                your personal data through your use of this website and/or
                application integration, including any data you may provide when
                accessing or using our services.
              </p>
              <p className="paragraph">
                Our website and services are not intended for children and we do
                not knowingly collect data relating to children.
              </p>
              <p className="paragraph">
                It is important that you read this privacy policy together with
                any other privacy policy or fair processing policy we may
                provide on specific occasions when we are collecting or
                processing personal data about you so that you are fully aware
                of how and why we are using your data. This privacy policy
                supplements other notices and privacy policies and is not
                intended to override them.
              </p>
            </div>
            <div>
              <h4 className="sub-head">Controller</h4>
              <p className="paragraph">
                AntStack Technologies Private Limited, trading as BizOs, is the
                data controller and is responsible for your personal
                data(collectively referred to as “BizOs”, "we", "us" or "our" in
                this privacy policy).
              </p>
              <p className="paragraph">
                We have appointed a data privacy manager who is responsible for
                overseeing questions in relation to this privacy policy. If you
                have any questions about this privacy policy, including any
                requests to exercise your legal rights, please contact data
                privacy manager using the details set out below.
              </p>
              <p className="paragraph">
                <span className="paragraph-link">
                  Full name of legal entity:
                </span>
                <spa>
                  <a href="https://www.antstack.com/" className="link-style">
                    AntStack Technologies Private Limited
                  </a>
                </spa>
                , trading as BizOs.
              </p>
              <p className="paragraph">
                <span className="paragraph-link">Email address: </span>
                <span>
                  <a href="mailto:connect@antstack.io" className="link-style">
                    connect@antstack.io
                  </a>
                </span>
              </p>
              <p className="paragraph">
                <span className="paragraph-link">Postal address: </span>Data
                Privacy Manager, BizOs, #620, 03rd Floor, 02nd Block, 01st Main,
                Dr.Rajkumar Road, Rajajinagar, Bengaluru - 560010, Karnataka,
                INDIA.
              </p>
              <p className="paragraph">
                You have the right to make a complaint at any time to The
                Ministry of Electronics and Information Technology (MeitY) , the
                Indian regulator for data protection issues
                <span>
                  <a href="https://www.meity.gov.in/" className="link-style">
                    (https://www.meity.gov.in/).
                  </a>
                </span>
                We would, however, appreciate the chance to deal with your
                concerns before you approach the MeitY so please contact us in
                the first instance.
              </p>
            </div>
            <div>
              <h4 className="sub-head">
                Changes to the privacy policy and your duty to inform us of
                changes
              </h4>
              <p className="paragraph">
                We keep our privacy policy under regular review.
              </p>
              <p className="paragraph">
                It is important that the personal data we hold about you is
                accurate and current. Please keep us informed if your personal
                data changes during your relationship with us.
              </p>
            </div>
            <div>
              <h4 className="sub-head">Third-party links</h4>
              <p className="paragraph">
                This website may include links to third-party websites, plug-ins
                and applications. Clicking on those links or enabling those
                connections may allow third parties to collect or share data
                about you. We do not control these third-party websites and are
                not responsible for their privacy statements. When you leave our
                website, we encourage you to read the privacy policy of every
                website you visit.
              </p>
            </div>
          </div>
          <div>
            <h3 className="head">2. The data we collect about you</h3>
            <div>
              <p className="paragraph">
                Personal data or personal information, means any information
                about an individual from which that person can be identified. It
                does not include data where the identity has been removed
                (anonymous data).
              </p>
              <p>
                We may collect, use, store and transfer different kinds of
                personal data about you which we have grouped together as
                follows:
              </p>
              <ul>
                <li>
                  <span className="paragraph-highlight">
                    Identity and Contact Data
                  </span>{" "}
                  includes name, username (including Email username), or similar
                  identifier and email address.
                </li>
                <li>
                  <span className="paragraph-highlight">Technical Data</span>{" "}
                  includes internet protocol (IP) address, your login data,
                  browser type, and version, time zone setting and location,
                  browser plug-in types and versions, operating system and
                  platform, and other technology on the devices you use to
                  access this website or our services.
                </li>
                <li>
                  <span className="paragraph-highlight">Usage Data</span>{" "}
                  includes information about how you use our website and
                  services.
                </li>
                <li>
                  <span className="paragraph-highlight">
                    Marketing and Communications Data
                  </span>{" "}
                  includes your preferences in receiving marketing from us and
                  your communication preferences.
                </li>
              </ul>
              <p className="paragraph">
                We also collect, use and share{" "}
                <span className="paragraph-highlight">Aggregated Data</span>{" "}
                such as statistical or demographic data for any purpose.
                Aggregated Data could be derived from your personal data but is
                not considered personal data in law as this data will not
                directly or indirectly reveal your identity. For example, we may
                aggregate your Usage Data to calculate the percentage of users
                accessing a specific website feature. However, if we combine or
                connect Aggregated Data with your personal data so that it can
                directly or indirectly identify you, we treat the combined data
                as personal data which will be used in accordance with this
                privacy policy.
              </p>
              <p className="paragraph">
                We do not collect any
                <span className="paragraph-highlight">
                  {" "}
                  Special Categories of Personal Data
                </span>{" "}
                about you (this includes details about your race or ethnicity,
                religious or philosophical beliefs, sex life, sexual
                orientation, political opinions, trade union membership,
                information about your health, and genetic and biometric data).
                Nor do we collect any information about criminal convictions and
                offenses.
              </p>
            </div>
            <div>
              <h4 className="sub-head">If you fail to provide personal data</h4>
              <p className="paragraph">
                Where we need to collect personal data by law, or under the
                terms of a contract we have with you, and you fail to provide
                that data when requested, we may not be able to perform the
                contract we have or are trying to enter into with you (for
                example, to provide you with goods or services). In this case,
                we may have to cancel a product or service you have with us, but
                we will notify you if this is the case at the time.
              </p>
            </div>
          </div>
          <div>
            <h3 className="head">3. How is your personal data collected?</h3>
            <div>
              <p>
                We use different methods to collect data from and about you,
                including through
              </p>
              <p>
                <span className="paragraph-highlight">
                  Direct interactions.
                </span>{" "}
                You may give us, or we may automatically receive, your Identity
                and Contact Data when you use our services.
              </p>
              <p>
                <span className="paragraph-highlight">
                  Automated technologies or interactions.
                </span>{" "}
                As you interact with our website and services, we will
                automatically collect Technical Data about your equipment,
                browsing actions, and patterns. We collect this personal data by
                using cookies and other similar technologies.
              </p>
              <p>
                <span className="paragraph-highlight">
                  Third parties or publicly available sources.
                </span>{" "}
                We may automatically receive your Identity and Contact Data when
                the company you work for integrates their application with our
                services.
              </p>
            </div>
          </div>
          <div>
            <h3 className="head">4. How we use your personal data</h3>
            <div>
              <p className="paragraph">
                We will only use your personal data when the law allows us to.
                Most commonly, we will use your personal data in the following
                circumstances:
              </p>
              <ul>
                <li>
                  Where we need to perform a contract we are about to enter into
                  or have entered into with you.
                </li>
                <li>
                  Where it is necessary for our legitimate interests (or those
                  of a third party) and your interests and fundamental rights,
                  do not override those interests.
                </li>
                <li> Where we need to comply with a legal obligation.</li>
              </ul>
              <p>
                Generally, we do not rely on consent as a legal basis for
                processing your personal data although, when required by law, we
                will get your consent before sending direct marketing
                communications to you via email or text message. You have the
                right to withdraw consent to marketing at any time by contacting
                us.
              </p>
            </div>
            <div>
              <h4 className="sub-head">
                Purposes for which we will use your personal data
              </h4>
              <p className="paragraph">
                We have set out below, in a table format, a description of all
                the ways we plan to use your personal data, and which of the
                legal bases we rely on to do so. We have also identified what
                our legitimate interests are where appropriate.
              </p>
              <p className="paragraph">
                Note that we may process your personal data for more than one
                lawful ground depending on the specific purpose for which we are
                using your data. Please contact us if you need details about the
                specific legal ground we are relying on to process your personal
                data where more than one ground has been set out in the table
                below.
              </p>
            </div>
            <div>
              <table className="pc-table">
                <tr>
                  <th>Purpose/Activity</th>
                  <th>Contact</th>
                  <th>
                    Lawful basis for processing including<br></br> basis of
                    legitimate interest
                  </th>
                </tr>
                <tr>
                  <td>To register you as a customer</td>
                  <td>(a) Identity and Contact</td>
                  <td>(a) Performance of a contract with you</td>
                </tr>
                <tr>
                  <td>To provide you with our services</td>
                  <td>(a) Identity and Contact</td>
                  <td>(a) Performance of a contract with you</td>
                </tr>
                <tr>
                  <td>
                    To manage our relationship with you which may include
                    notifying you about changes to our terms or privacy policy
                  </td>
                  <td>(a) Identity and Contact</td>
                  <td>(a) Performance of a contract with you</td>
                </tr>
                <tr>
                  <td>
                    To administer and protect our business and this website
                    (including troubleshooting, data analysis, testing, system
                    maintenance, support, reporting and hosting of data)
                  </td>
                  <td>(a) Identity and Contact</td>
                  <td>
                    (a) Necessary for our legitimate interests (for running our
                    business, provision of administration and IT services,
                    network security, to prevent fraud and in the context of a
                    business reorganisation or group restructuring exercise)
                  </td>
                </tr>
                <tr>
                  <td>
                    To deliver relevant website content and advertisements to
                    you and measure or understand the effectiveness of the
                    advertising we serve to you
                  </td>
                  <td>(a) Identity and Contact</td>
                  <td>
                    Necessary for our legitimate interests (to study how
                    customers use our products/services, to develop them, to
                    grow our business and to inform our marketing strategy)
                  </td>
                </tr>
                <tr>
                  <td>
                    To use data analytics to improve our website,
                    products/services, marketing, customer relationships and
                    experiences
                  </td>
                  <td>(a) Technical</td>
                  <td>
                    Necessary for our legitimate interests (to define types of
                    customers for our products and services, to keep our website
                    and services updated and relevant, to develop our business
                    and to inform our marketing strategy)
                  </td>
                </tr>
                <tr>
                  <td>
                    To send marketing to you when you have given your consent.
                  </td>
                  <td>(a) Identity and Contact</td>
                  <td>Consent</td>
                </tr>
              </table>
            </div>
            <div>
              <h4 className="sub-head">Promotional offers from us</h4>
              <p>
                We may use your Identity, Contact, Technical, Usage, and Profile
                Data to form a view on what we think you may want or need, or
                what may be of interest to you. This is how we decide which
                products, services, and offers may be relevant for you.
              </p>
            </div>
            <div>
              <h4 className="sub-head">Third-party marketing</h4>
              <p>
                We may use the company’s name and logo for AntStack’s marketing
                purposes. We will get your express opt-in consent before we
                share your personal data with any third party for marketing
                purposes.
              </p>
            </div>
            <div>
              <h4 className="sub-head">Opting out</h4>
              <p className="paragraph">
                You can ask us or third parties to stop sending you marketing
                messages by contacting us at any time.
              </p>
            </div>
            <div>
              <h4 className="sub-head">Change of purpose</h4>
              <p className="paragraph">
                We will only use your personal data for the purposes for which
                we collected it, unless we reasonably consider that we need to
                use it for another reason and that reason is compatible with the
                original purpose. If you wish to get an explanation as to how
                the processing for the new purpose is compatible with the
                original purpose, please contact us.
              </p>
              <p className="paragraph">
                If we need to use your personal data for an unrelated purpose,
                we will notify you and we will explain the legal basis which
                allows us to do so.
              </p>
              <p className="paragraph">
                Please note that we may process your personal data without your
                knowledge or consent, in compliance with the above rules, where
                this is required or permitted by law.
              </p>
            </div>
          </div>
          <div>
            <h3 className="head">5. Disclosures of your personal data</h3>
            <div>
              <p className="paragraph">
                We may share your personal data with the parties set out below
                for the purposes set out in the table above.
              </p>
              <ul>
                <li>
                  External service providers that we work with operate our
                  business, website and services.
                </li>
                <li>
                  Third parties to whom we may choose to sell, transfer or merge
                  parts of our business or our assets.
                </li>
              </ul>
              <p className="paragraph">
                Alternatively, we may seek to acquire other businesses or merge
                with them. If a change happens to our business, then the new
                owners may use your personal data in the same way as set out in
                this privacy policy.
              </p>
              <p className="paragraph">
                We require all third parties to respect the security of your
                personal data and to treat it in accordance with the law. We do
                not allow our third-party service providers to use your personal
                data for their own purposes and only permit them to process your
                personal data for specified purposes and in accordance with our
                instructions.
              </p>
            </div>
          </div>
          <div>
            <h3 className="head">6. International transfers</h3>
            <div>
              <p className="paragraph">
                Some of the external service providers to which we need to
                transfer personal data are based outside the INDIA. This means
                that their processing of your personal data will involve a
                transfer of data outside the INDIA.
              </p>
              <p className="paragraph">
                Whenever we transfer your personal data out of the INDIA, we
                ensure a similar degree of protection is afforded to it by
                ensuring that appropriate safeguards are implemented.
              </p>
            </div>
          </div>
          <div>
            <h3 className="head"> 7. Data security</h3>
            <div>
              <p className="paragraph">
                We have put in place appropriate security measures to prevent
                your personal data from being accidentally lost, used, or
                accessed in an unauthorised way, altered or disclosed. In
                addition, we limit access to your personal data to those
                employees, agents, contractors, and other third parties who have
                a business need to know. They will only process your personal
                data on our instructions, and they are subject to a duty of
                confidentiality.
              </p>
              <p className="paragraph">
                We have put in place procedures to deal with any suspected
                personal data breach and will notify you and any applicable
                regulator of a breach where we are legally required to do so.
              </p>
            </div>
          </div>
          <div>
            <h3 className="head">8. Data retention</h3>
            <div>
              <h4>How long will you use my personal data for?</h4>
              <p className="paragraph">
                We will only retain your personal data for as long as reasonably
                necessary to fulfil the purposes we collected it for, including
                for the purposes of satisfying any legal, regulatory, tax,
                accounting or reporting requirements. We may retain your
                personal data for a longer period in the event of a complaint or
                if we reasonably believe there is a prospect of litigation in
                respect to our relationship with you.
              </p>
              <p className="paragraph">
                To determine the appropriate retention period for personal data,
                we consider the amount, nature and sensitivity of the personal
                data, the potential risk of harm from unauthorised use or
                disclosure of your personal data, the purposes for which we
                process your personal data and whether we can achieve those
                purposes through other means, and the applicable legal,
                regulatory, tax, accounting or other requirements.
              </p>
            </div>
          </div>
          <div>
            <h3 className="head">9. Your legal rights</h3>
            <p className="paragraph">
              Under certain circumstances, you have various rights under data
              protection laws in relation to your personal data, including the
              right to:
            </p>
            <ul>
              <li>request access to your personal data;</li>
              <li>request correction of your personal data;</li>
              <li>request erasure of your personal data;</li>
              <li>object to the processing of your personal data;</li>
              <li>request restriction of processing your personal data;</li>
              <li>
                request transfer of your personal data; and withdraw consent.
              </li>
            </ul>
            <p>
              If you wish to exercise any of the rights set out above, please
              contact us.
            </p>
            <div>
              <h4 className="sub-head">No fee usually required</h4>
              <p>
                You will not have to pay a fee to access your personal data (or
                to exercise any of the other rights). However, we may charge a
                reasonable fee if your request is clearly unfounded, repetitive
                or excessive. Alternatively, we could refuse to comply with your
                request in these circumstances.
              </p>
            </div>
            <div>
              <h4 className="sub-head">What we may need from you</h4>
              <p>
                We may need to request specific information from you to help us
                confirm your identity and ensure your right to access your
                personal data (or to exercise any of your other rights). This is
                a security measure to ensure that personal data is not disclosed
                to any person who has no right to receive it. We may also
                contact you to ask you for further information in relation to
                your request to speed up our response.
              </p>
            </div>
            <div>
              <h4 className="sub-head">Time limit to respond</h4>
              <p>
                We try to respond to all legitimate requests within one month.
                Occasionally it could take us longer than a month if your
                request is particularly complex, or you have made a number of
                requests. In this case, we will notify you and keep you updated.
              </p>
            </div>
          </div>
          <p className="updated-on">Updated - 26th December 2022.</p>
        </div>
      </div>
      <div className="privacy-footer">
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
