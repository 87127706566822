import React, { useState } from "react";
import "./SignUp.css";
import Airtable from "airtable";

const base = new Airtable({ apiKey: "keyPy0EpC85xfdBhW" }).base(
  "app1mzenEUngejC4B"
);

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    base("signups").create(
      [
        {
          fields: {
            Email: email,
            createdAt: new Date(),
          },
        },
      ],
      function (err, records) {
        if (err) {
          setSuccess("");
          setError("Unable to submit. Please try again!");
          console.error(err);
          return;
        }
        setSuccess(true);
        setEmail("");
        setError("");
      }
    );
  };

  return (
    <form className="input-form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col">
          <div className="form-row-message">
            <label htmlFor="email" className="key">
              Curious...? Sign Up and get notified!
            </label>
          </div>

          <div className="val">
            <input
              name="email"
              id="email"
              className="input-id"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email ID"
              required
            />
            <div className="form-row">
              <div className="actions">
                <button className="signup-button" type="submit">
                  Notify Me
                </button>
              </div>
            </div>
          </div>
        </div>
        {success ? (
          <div className="Confirmation">Thank you for your interest. We will reach out to you soon!</div>
        ) : null} 
      </div>

      {success ? (
        <div className="form-row">
          <div className="alert">{success}</div>
        </div>
      ) : null}
      {error ? (
        <div className="form-row">
          <div className="alert">{error}</div>
        </div>
      ) : null}
    </form>
  );
};

export default SignUp;
