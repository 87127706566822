import React, { useState, useEffect } from "react";
import "./ChatBot.css";
import Cbot from "../../images/comment-bot.svg";
import bike from "../../images/sfree-botImg.svg";
import cross from "../../images/bot-close.svg";

const ChatBot = () => {
  const [bot, setBot] = useState(false);
  const [initial, setInitial] = useState(false);

  const handleClick = () => {
    setBot(!bot);
  };

  useEffect(() => {
    const openBot = (e) => {
      setTimeout(() => {
        if (!initial) {
          setBot(!bot);
          setInitial(true);
        }
      }, 1500);

      window.removeEventListener("pointermove", openBot);
    };
    window.addEventListener("pointermove", openBot);
    return () => {
      window.removeEventListener("pointermove", openBot);
    };
  }, [initial, bot]);

  return (
    <div className="bot-parent">
      {bot && (
        <div className="bot-msg">
          <img src={bike} className="bot-popup" alt="michael-jackson" />
          <p>
            <span className="bot-txt">BizOS</span> is designed with{" "}
            <span className="bot-txt">support free</span> User Experience 😀
          </p>
        </div>
      )}

      <button className="bot-btn" onClick={handleClick}>
        {!bot ? (
          <img src={Cbot} className="bot-logo" alt="antstack-logo" />
        ) : (
          <img
            src={cross}
            className="bot-logo"
            alt="antstack-logo"
            onClick={handleClick}
          />
        )}
      </button>
    </div>
  );
};

export default ChatBot;
